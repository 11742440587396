<template>
  <div class="container-rodape">
    <div class="row">
      <div class="column large-12 container-rodape-flex">
        <span class="agencia">www.<b>wapstore</b>.com.br</span>
        <span class="direitosReservados">{{ anoAtual.getFullYear() }} | wap.store | todos os direitos reservados</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      anoAtual: new Date(),
    }
  },
}
</script>

<style scoped>
.container-rodape {
  background: #1B1B1B;
  width: 100%;
  min-height: 78px;
  margin-top: 54px;
}
.agencia {
  font-size: 16px;
  color: #fff;
}
.direitosReservados {
  font-size: 12px;
  color: #fff;
}
.container-rodape-flex {
  padding: 31px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>